// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as InviteSignUpErrorScss from "./InviteSignUpError.scss";

var css = InviteSignUpErrorScss;

function InviteSignUpAgreementError(props) {
  var results = props.results;
  if (results === undefined) {
    return null;
  }
  var message = results.isAgreeWithTerms;
  if (message.TAG === "Ok") {
    return null;
  } else {
    return JsxRuntime.jsx("div", {
                children: message._0,
                className: css.fieldError
              });
  }
}

var make = InviteSignUpAgreementError;

export {
  css ,
  make ,
}
/* css Not a pure module */
