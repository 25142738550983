// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as HelpBlock from "../help-block/HelpBlock.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as InviteSignUpScss from "./InviteSignUp.scss";
import * as InviteSignUpProvider from "./InviteSignUpProvider.res.js";

var css = InviteSignUpScss;

function InviteSignUp$InviteSignUp(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(H1.make, {
                                          children: "Update information"
                                        }),
                                    JsxRuntime.jsx(InviteSignUpProvider.make, {
                                          email: props.email,
                                          token: props.token
                                        })
                                  ],
                                  className: Cx.cx([
                                        css.column,
                                        css.signUp
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(HelpBlock.make, {}),
                                  className: Cx.cx([
                                        css.column,
                                        css.helpContainer
                                      ])
                                })
                          ],
                          className: css.row
                        }),
                    className: css.container
                  }),
              className: css.signUpContainer
            });
}

var InviteSignUp = {
  make: InviteSignUp$InviteSignUp
};

function InviteSignUp$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(InviteSignUp$InviteSignUp, {
              email: props$1.email,
              token: props$1.token
            });
}

var $$default = InviteSignUp$default;

export {
  css ,
  InviteSignUp ,
  $$default as default,
}
/* css Not a pure module */
